@import '../variables';

body {
    margin: 0;
    font-family: 'Noto Sans JP', sans-serif;
}

.loader {
    background: url("../../images/loader.gif") rgba(255, 255, 255, 0.5) center no-repeat;
    background-size: 7%;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    transition: .3s all ease-in-out;
    opacity: 1;
}

.required-mark:before {
    content: '* ';
    color: $red-3;
}

.top-page-link {
    top: 100px;
    position: absolute;
    left: 175px;
}

.helper-text {
    font-size: 12px !important;
    color: #747272;
}

.fade-enter {
    opacity: 0;
}

.fade-exit {
    opacity: 1;
}

.fade-enter-active {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
}

.attendance-check-icon {
    color: $button-primary-color;
    font-weight: bold;
}

.fade-enter-active,
.fade-exit-active {
    transition: opacity 300ms;
}

.ant-btn, .ant-modal-content, .ant-input {
    border-radius: $border-radius !important;
}

.ant-input[readonly] {
    background: #F5F5F5;
}

.link {
    cursor: pointer;
}

.breadcrumbs {
    padding: 60px 182px 30px 182px;
}

.text-center {
    text-align: center;
}

.ant-carousel {
    .slick-list {
        margin: 0 30px;
    }

    .slick-prev,
    .slick-next {
        top: 0;
        height: 100%;
        display: flex !important;
        align-items: center;
        margin: 0;
        font-size: 24px;
        background: white !important;
    }

    .slick-prev,
    .slick-next,
    .slick-prev:hover,
    .slick-next:hover {
        color: currentColor;
        z-index: 2;
    }

    .slick-prev,
    .slick-prev:hover {
        left: 2px;
    }

    .slick-next,
    .slick-next:hover {
        right: 2px;
    }
}


.login-modal,
.forgot-password-card,
.forgot-password-modal,
.change-password-modal,
.consultation-cancel-modal,
.custom-confirmation-modal,
.password-change-success-modal,
.request-meeting-modal {
    width: 600px !important;
}

.eligibility-modal,
.profile-preview-modal,
.instruction-modal {
    width: 800px !important;
}

.registration-card {
    width: 60% !important;

    .registration-separator {
        border: 1px solid $light-blue-3;
    }
    
    .registration-terms-content{
        display:flex;
        .terms-subtext {
            font-size: 15px !important;
        }
    }

    .registration-terms-checkbox {
        margin-top: 36px;
        padding-left: 40px;
        a, div, p {
            font-size: 18px !important;
        }
    }
}

.profile-preview-modal {
    .label {
        font-weight: bold;
        display: inline;
        color: $gray-5;
    }
}

//Display first error message only in rules validation
.dashboard-content, .login-modal, .registration-modal, .forgot-password-modal, .change-password-modal {
    form div.ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
        display: none;
    }
}

.daido-info {
    width:19%
}

.login-modal,
.forgot-password-card,
.forgot-password-modal,
.registration-card,
.eligibility-modal,
.change-password-modal,
.consultation-cancel-modal,
.custom-confirmation-modal,
.password-change-success-modal,
.request-meeting-modal,
.profile-preview-modal {
    .ant-modal-body {
        padding: 50px 13%;
    }

    .title {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 15px;
        text-align: center;
    }

    .subtitle {
        font-size: 12px;
        text-align: center;
    }

    form {
        margin: 35px auto;
        width: 300px;

        .link {
            display: block;
            margin: 20px 0;
            font-size: 13px;
            color: #656565;
        }
    }

    .helper-text {
        font-size: 12px;
        color: #747272;

        a {
            text-decoration: none;
        }
    }

    .action-buttons {
        display: flex;
    }
}

.ant-btn-primary {
    background-color: $button-primary-color;
    color: #ffffff;
    border: none;
}

.ant-btn {
    box-shadow: none;
}

.success-modal {
    text-align: center;
    align-content: center;

    .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }

    img {
        margin: 30px;
    }

    .message {
        margin: 10px 10px;
    }
}

.pointer-event-none {
    pointer-events: none;
}

.display-block {
    display: block;
}

.privacy-policy-table {
    table, td, th {
        border: 1px solid;
    }

    th, td:first-child {
        width: 20%
    }

    table {
        width: 70%;
        border-collapse: collapse;
    }
}

.validation-error-modal .ant-modal-content {
    background: $red-2;
}

.notification-status-unread {
    color:$light-blue-4 !important;
    font-size:14px;
    font-weight: bold;
}

.notification-container_read {
    color:$gray-4 !important;
    .notification-status-read {
        font-size:14px;
        font-weight: bold;
    }
}

.notification-btn {
    height:21px;
    line-height: 5px;
}

.system-notification-container {
    background: #0461A2;
    padding: 10px 136px;
    height: $notification-height;

    p {
        color: white;
        text-decoration: underline;
        cursor: pointer;
    }
}

.system-notification-container + .landing-navbar,
.system-notification-container + .dashboard-navbar {
    top: $notification-height;
}

.system-notification-container + .dashboard-navbar + .top-page-link {
    top: calc(100px + $notification-height);
}

mark {
    background-color: yellow !important;
    padding: 0 !important;
}

/**
   Reusable custom spacing and size classes
 */
@each $width in 100px, 200px, 300px {
    .w-#{$width} {
        width: $width;
    }
}

.m-15px {
    margin: 15px !important;
}

.mt-20px {
    margin-top: 20px !important;
}

.mt-30px {
    margin-top: 30px !important;
}

.mt-43px {
    margin-top: 43px !important;
}

.me-20px {
    margin-right: 20px !important;
}

.my-30px {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.p-24px {
    padding: 24px !important;
}

.pl-14px {
    padding-left: 14px !important;
}

.px-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
}

.pt-33px {
    padding-top: 33px !important;
}

.min-width-100px {
    min-width: 100px;
}

/**
   Reusable custom color classes
 */
$colors: (
    primary-color: $button-primary-color,
    red-1: $red-1,
    red-2: $red-2,
    red-3: $red-3,
    light-blue-1: $light-blue-1,
    light-blue-2: $light-blue-2,
    light-blue-3: $light-blue-3,
    light-blue-4: $light-blue-4,
    light-blue-5: $light-blue-5,
    text-gray: $gray-4,
);

@each $className, $color in $colors {
    .#{$className} {
        color: $color !important;
    }
}
