.dashboard-navbar {
    padding: 5px 10%;

    .logo {
        img {
            height: 35px;
            align-self: start;
        }

        span {
            font-size: 10px;
        }
    }
}
