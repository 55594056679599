.login-modal .ant-modal-body,
.forgot-password-card .ant-modal-body,
.forgot-password-modal .ant-modal-body,
.registration-card .ant-modal-body,
.eligibility-modal .ant-modal-body,
.change-password-modal .ant-modal-body,
.consultation-cancel-modal .ant-modal-body,
.custom-confirmation-modal .ant-modal-body,
.request-meeting-modal .ant-modal-body,
.profile-preview-modal .ant-modal-body {
    padding: 50px 10%;
}

.breadcrumbs {
    padding: 20px 10px 0 10px;
}

.login-modal,
.forgot-password-card,
.forgot-password-modal,
.registration-card,
.eligibility-modal,
.change-password-modal,
.consultation-cancel-modal,
.custom-confirmation-modal,
.request-meeting-modal,
.profile-preview-modal {
    form {
        width: 100%;
    }

    .action-buttons {
        flex-direction: column;
    }
}

.d-xs-none {
    display: none;
}

.daido-info {
    width: 100%;
}

.privacy-policy-table {
    table {
        width: 100%;
    }
}

.system-notification-container {
    padding: 10px 5% !important;
}

.registration-card {
    .registration-terms-content {
        display: block;
    }

    .registration-terms-checkbox {
        margin-top: 0;
        padding-left: 0px;
    }
}