.landing-navbar {
    position: absolute;
    top: 0;
    padding: 19px 136px;
    display: flex;
    width: 100%;
    z-index: 100;

    .buttons {
        margin-left: auto;
        align-self: center;
    }

    .down-icon {
        line-height: 24px;
        float: right;
        font-size: 14px;
    }

    .buttons button {
        height: 40px;
        min-width: 160px;
        background-color: #F2F2F2;
        color: #01A0DD;

        &:hover {
            background-color: #01A0DD;
            color: #F2F2F2;
        }
    }

    .logo {
        display: flex;
        flex-direction: column;

        img {
            height: 45px;
            align-self: start;
        }

        span {
            font-size: 12px;
            color: #FFF;
            letter-spacing: 1px;
        }
    }
}
