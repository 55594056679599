@import 'src/assets/styles/variables';

body#public-support {
    .container {
        margin: 55px auto;
        min-height: calc(100vh - ($navbar-height + $footer-height));
    }

    section.header {
        position: relative;
        height: 500px;
        background: url("../../images/kouteki_landing_header.jpg") center no-repeat;
        width: 100%;
        background-size: cover;
        background-position-y: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 30%;
        }

        .content {
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    section.sub-header {
        position: relative;
        background: url("../../images/kouteki_header2.png") center no-repeat;
        width: 100%;
        background-size: cover;
        height: 225px;

        img {
            width: 30%;
        }

        .content {
            background: rgba(19, 90, 117, 0.5);
            background-size: cover;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            height: 100%;

            .title {
                margin-top: 10%;
                font-size: 36px;
                color: #FFFFFF;
            }
        }
    }

    section.content {
        padding: 20px 182px 100px 182px;
        min-height: calc(100vh - ($navbar-height + $footer-height))
    }

    section.home-content {
        &:not(:last-child) {
            margin-bottom: 50px !important;
        }

        .title {
            color: #01A0DD;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 15px;
        }

        .title, p {
            text-align: center;
        }

        p {
            font-size: 16px;
        }

        .concept-meaning {
            .ant-card {
                min-height: 180px;
                font-size: 16px;
                line-height: 24px;
                background: $light-blue-1;
                border: 1px solid $light-blue-2;
            }

            .concept-title {
                margin-bottom: 10px;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }

    section.diagnose-result-container {
        .title {
            color: #01A0DD;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 24px;
        }

        .consultation-text {
            display: flex;
        }

        .consultation-label {
            height: 30px;
        }

        .external-link-text {
            display: flex;
        }

        .external-link {
            height: 30px;
        }
    }

    footer.landing-footer {
        background-image: url("../../images/footer_banner.png");
        color: #ffffff;

        .footer-wrapper {
            background: linear-gradient(
                    180deg,
                    rgba(0, 151, 254, 0.7) -8.91%,
                    rgba(0, 12, 20, 0.7) 74%
            );
        }
    }

    .search-form-container {
        width: 75%;
        margin: auto;
    }

    .search-form-margin-top {
        margin-top: -10% !important;
    }

    .search-form-card form {
        .ant-form-item-label label {
            font-weight: bold;
        }

        .ant-btn {
            background-color: #2a6cb0;
            color: #fff;

            &:hover {
                background-color: #017FD6;
            }

            &:not(.search-keyword-btn) {
                height: 60px;
                width: 180px;
            }
        }
    }

    .list-center {
        margin-left: auto;
        margin-right: auto;
        width: 37%;
        border: 1px dashed #01A0DD;
        padding: 20px;
    }

    .sub-description {
        margin-top: 5px;
        font-size: 11px;
    }

    .kouteki-card-size {
        min-height: 320px !important;
    }

    .checkbox-size * {
        width: 20px;
        height: 20px;
    }

    .link-desc {
        font-size: 16px;
        font-weight: 700;
    }

    .pop-up-table-list {
        vertical-align: baseline;
    }

    .service-card {
        height: 500px;
        text-align: center;

        img {
            height: 200px;
        }

        .card-title {
            margin: 15px 0;
            font-size: 18px;
            font-weight: bold
        }

        p {
            font-size: 12px;
            text-align: left;
        }
    }

    .arrow-icon {
        font-size: 40px !important;
    }

    .ant-carousel {
        .carousel-page {
            padding: 20px 10px;
        }

        .slick-prev,
        .slick-prev:hover {
            color: #2a6cb0;
        }

        .slick-next,
        .slick-next:hover {
            color: #2a6cb0;
        }

        .ant-btn {
            color: #2a6cb0;
            border-color: #2a6cb0;
        }

        p {
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 19px !important;
        }

        .recommendation {
            background: $light-blue-1;
        }

    }

    .search-content {
        .page-count {
            font-weight: bold;
            font-size: 18px;
            text-align: center;
            margin-bottom: 10px;
        }

        .search-result {
            .ant-checkbox-wrapper {
                width: calc(100% - 60px);
            }

            .ant-checkbox-wrapper > span:nth-child(2) {
                line-height: 18px;
                overflow: hidden;
            }

            .content-wrapper {
                display: flex;
                overflow: hidden;
            }

            .title, .description {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .title {
                min-width: 45vh;
                margin-right: 15px;
                font-weight: bold;
            }

            .description {
                min-width: 55vh;
            }

            .ant-list-split .ant-list-item {
                border: 1px solid #dedede;
                margin-bottom: 15px;
                padding: 12px;
                border-radius: $border-radius;

                &:hover {
                    background-color: #f0f0f0;
                }
            }
        }

        .submit-button {
            height: 32px;
            width: 150px;
        }
    }

    .carousel-page, .search-result {
        .ant-btn:not(:disabled) {
            border-color: #006BB5;
            color: #006BB5;

            &:hover {
                background-color: #006BB5;
                color: #fff;
            }
        }
    }

    .note {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }

    .consult-badge {
        background: #66B828;
        color: #FFFFFF;
        padding: 10px;
        text-align: center;
        display: inline-block;
    }

    .diagnose-list {
        background: #F2F2F2;
        align-items: center;
        margin-bottom: 20px;
    }

    .footer-items a {
        color: #FFFFFF !important;
    }

    .search-funds-details {
        .content {
            white-space: pre-line;
        }

        .title {
            font-size: 24px;
            color: #01A0DD;
            display: inline-block;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .badge {
            background-color: #F6FFED;
            border: 1px solid #B7EB8F;
            border-radius: 2px;
            color: #52C41A;
            font-size: 12px;
            padding: 2px 5px;
            vertical-align: middle;
            margin-left: 10px;
        }

        section {
            margin-bottom: 20px;

            .name {
                color: #01A0DD;
                font-weight: bold;
            }
        }

        .helper-text {
            border: 1px solid #000000;
            color: #FF4D4F;
            padding: 5px;
            display: inline-block;
        }

        .requirements-section {
            margin-top: 30px;
            background-color: #F0F0F0;
            text-align: center;
            padding: 50px;

            .requirements {
                font-size: 36px;
                color: #444444;
                font-weight: bold
            }
        }

        .more-details {
            background-color: #EAF0F7;
            color: #2A6CB0;
            padding: 30px 20px;
            width: 100%;
            align-items: center;

            button.ant-btn {
                background-color: #006BB5;
                color: #fff;
            }

            button.external {
                float: right;
            }
        }
    }

    .checkbox-desc * {
        font-size: 16px !important;
    }

    button.diagnose-button {
        height: 40px;
        width: 320px;
        background-color: #006BB5;
        color: #fff;
    }

    .match-failed-modal .ant-modal-body {
        text-align: center;
        padding: 52px 80px;
    }

    .match-failed-modal,
    .match-loading-modal {
        .title {
            font-size: 16px;
            font-weight: bold;
        }
    }

    .topic-list {
        padding-top: 0 !important;
        min-height: calc(100vh - ($navbar-height + $footer-height));
    }

    .topics {
        margin: 2vw 12vw;
        text-align: center;

        .ant-card {
            background: #F2F2F2;
            margin-top: 10px;

            .topic-date-col {
                font-size: 12px;
                padding: 20px;

                .topic-date-container {
                    background: #999999;
                    color: #ffffff;
                    padding: 2px;
                    text-align: center;
                }
            }

            .topic-text-col {
                font-size: 14px;
                text-align: justify;
                padding: 20px;
            }

            .topic-button-col {
                padding: 10px;

                .ant-btn {
                    width: 88px;
                    font-size: 11px;
                }
            }
        }
    }

    .topic-container {
        padding: 0 182px 30px 182px;
        min-height: calc(
            100vh
            - 225px// navbar
            - 250px// footer
            - 112px // breadcrumbs
        );

        .title {
            color: black;
            margin-bottom: 5px;
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
        }

        p.content {
            white-space: pre-line;
        }

        .badge {
            background-color: #F6FFED;
            border: 1px solid #B7EB8F;
            border-radius: 2px;
            color: #52C41A;
            font-size: 12px;
            padding: 2px 5px;
            vertical-align: middle;
        }
    }

    .instruction-modal {
        .title {
            color: #01A0DD;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 24px;
            text-align: center;
        }

        .ant-modal-body {
            padding-left: 5%;
        }

        .text-indent {
            display: inline-block;
            text-indent: 17px;
        }

        .btn-group {
            display: flex;
            gap: 10%;
            justify-content: center;
        }

        .note {
            border: 1px solid $light-blue-5;
            line-height: 19px;
            padding: 5px;
            margin: 10px 23px 10px 0;
        }

        .ant-image-mask {
            background: rgba(0, 0, 0, 0);
            align-items: flex-end;
            justify-content: flex-start;
            opacity: 1;

            &:hover {
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
            }

            .ant-image-mask-info {
                margin: 10px;
                padding: 1px 10px;
                background: rgba(0, 0, 0, 0.30);
                border-radius: 5px;
            }
        }
    }
}
