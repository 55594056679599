.landing-navbar {
    padding: 19px 5%;

    .buttons button {
        min-width: 140px;
    }

    .logo {
        img {
            height: 35px;
            align-self: start;
        }

        span {
            font-size: 10px;
        }
    }
}
