body#work-regulation .inquiries,
body#public-support .inquiries {
    .ant-collapse-content-box {
        .ant-row {
            flex-flow: row;
        }
    }

    .ant-collapse-header {
        display: block !important;
    }

    .contact-us-form {
        .title {
            color: #01A0DD;
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            display: block;
            text-align: center;
        }

        .container {
            width: 75%;
            margin: auto;
        }
    }

    .a-char, .a-content, .q-question, .q-char {
        color: #000000;
        font-style: normal;
    }

    .a-char, .q-char {
        text-align: center;
    }

    .a-char {
        text-align: left;
        font-weight: 600;
        font-size: 24px;
    }

    .a-content {
        font-size: 16px;
        white-space: pre-line;

    }

    .q-char, .q-question {
        line-height: 14px;
    }

    .q-char {
        font-weight: 600;
        font-size: 24px;
    }

    .q-question {
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
    }

    .ant-collapse-header {
        border-radius: 3px;
    }
}
