@import 'src/assets/styles/variables';

body#dashboard {
    background: #f0f2f5;

    .ant-menu-root {
        flex-direction: column;
        display: flex;
        height: 100%;
        border-right: none;
    }

    .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 25px;

        &-primary {
            color: $light-blue-4;
        }

        &-primary-lg {
            font-size: 16px;
            color: $light-blue-4;
        }
    }

    .secondary-button {
        background-color: $button-secondary-color;
        color: black;
        border: 1px solid $button-secondary-outline;
        width: 100px;
    }

    .notification-count {
        float: right;
        margin: 10px;
    }

    .menu-name-too-long {
        width: 300px !important;
        white-space: unset !important;
        line-height: 20px !important;
    }

    .header-container {
        display: flex;
    }

    .page-actions {
        margin-left: auto;

        &-items {
            width: 250px;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    .dashboard-content {
        padding: 135px 175px 100px;
        flex-direction: row;

        .ant-layout-sider {
            padding: 25px 0;
            max-height: calc(100% - 250px);
            background-color: #fff;
            overflow-x: hidden;
        }

        .ant-menu-item-selected {
            background-color: #006BB5;

            &, a {
                color: #fff;
            }

            &:after {
                border: none;
            }
        }

        .ant-layout-content {
            margin-left: 300px;
            flex: none;
        }

        .ant-breadcrumb {
            margin-bottom: 10px;
        }

        .content {
            background-color: #fff;
            padding: 20px;
        }

        .ant-badge-count {
            box-shadow: 0 0 0 1px $red-3;
        }
    }

    .dashboard-sider {
        overflow: auto;
        height: 100vh;
        position: fixed;
        left: 175px;
    }

    .dashboard-footer {
        background-color: $button-primary-color;
        text-align: center;
        height: 30px;
        padding: 5px 0;
        color: #fff;
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .dashboard-management {
        .dashboard-profile {
            padding: 0 50px;
            word-wrap: break-word;

            li {
                list-style: none;
            }

            table, td {
                border: 1px solid #ccc;
                border-collapse: collapse;
            }

            td.name {
                background-color: $button-primary-color;
                color: white;
                width: 15%;
            }

            td.value {
                word-break: break-all;
                width: 35%;
            }
        }

        .profile-separator {
            border: 1px solid $light-blue-3;
        }

        button.edit-profile {
            margin-left: auto;
            background: $button-primary-color;
            color: #fff;
        }

        .personal-info {
            .title {
                font-size: 16px;
                margin-bottom: 5px;
            }
        }

        button.unsubscribe-button {
            float: right;
        }

        .change-password {
            color: #01A0DD;
            text-decoration: underline;
            margin-bottom: 45px;
            cursor: pointer;
        }
    }

    .dashboard-notifications {
        .notification {
            &-container {
                padding: 10px 20px;
                border-radius: 3px;
                border: 1px solid rgba(196, 196, 196, 0.4);
                margin-bottom: 15px;

                &_read {
                    background-color: $gray-1;
                }

                &_unread {
                    background-color: $yellow-1;
                }
            }

            &-title {
                font-weight: bold;
                font-size: 14px;
            }

            &-btn {
                background-color: $button-secondary-color;
                color: black;
                border: 1px solid $button-secondary-outline;
                width: 100px;
            }
        }
    }

    .unsubscribe-modal {
        .unsubscribe-content {
            padding: 30px;
        }

        .unsubscribe-description {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0;
            text-align: center;
        }
    }

    .consultation-list {
        .title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 25px;
        }

        .table-filter {
            float: right;
        }

        .subtitle {
            font-size: 16px;
            //color: $gray-4;
        }

        &-pagination {
            margin: 15px 0;
        }
    }

    .consultation-details {
        .title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 25px;
        }

        .grant-checklist {
            margin: 10px 0;
        }

        .consultation-detail {
            margin: 10px 0;

            textarea:disabled {
                background-color: #ffffff !important;
            }
        }

        .cancel-button {
            float: right;
        }

        button.back-button {
            width: 200px;
            margin-top: 30px;
        }

        button.consult {
            margin-top: 30px;
        }

        .consult-note {
            margin: 20px auto;
            width: 60%;
        }
    }

    .dashboard-calendar {
        .meeting-count {
            padding: 15px 20px;
            border-radius: 3px;
            border: 1px solid rgba(196, 196, 196, 0.4);
            margin-bottom: 15px;
            background-color: $gray-1;

            &-title {
                font-size: 16px;
                font-weight: bold;
            }
        }

        .divider {
            margin: 30px 0;
            height: 2px;
            border: none;
            background-color: $button-primary-color;
        }

        .subtitle {
            font-size: 16px;
            color: $gray-4;
        }

        .ant-picker-body {
            z-index: 0;
        }

        .month-picker {
            text-align: right;

            &-text {
                font-weight: bold;
                font-size: 16px;
            }

            .ant-btn {
                border: none;
                color: black;
            }
        }

        ul {
            list-style-type: none !important;
            padding-inline-start: 0;
        }
    }

    .specialist-review {
        &-card {
            background: $gray-2;
            border-color: $gray-3;
            margin: 10px 0;

            .ant-card-body {
                padding: 15px;
            }
        }

        &-pagination {
            margin: 15px 0;
        }

        &-image {
            height: 50px;
        }

        &-name {
            font-size: 18px;
        }

        &-done {
            color: $button-primary-color;
        }

        .specialist-label {
            color: $gray-4
        }

        .ant-btn-primary {
            background: $button-primary-color;
        }

        .ant-btn-primary[disabled] {
            background: $gray-2;
        }

        .fund-info {
            background: $light-blue-1;
            border-color: $light-blue-1;

            .ant-card-body {
                padding: 15px;
            }
        }

        .feedback-container {
            margin: 15px 0;
            background: $gray-2;
            border-color: $gray-3;

            .ant-card-body {
                padding: 15px;
            }

            h1 {
                font-size: 16px;
                text-decoration: underline;
                margin-bottom: 10px;
            }

            h2 {
                font-size: 12px;
                margin-bottom: 5px;
            }

            h3 {
                font-size: 14px;
                margin: 10px 0;
            }
        }

        .feedback-buttons {
            .ant-btn {
                width: 200px;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }

    .working-regulation, .kisoku-meetings {
        .upload-note {
            font-size: 12px;
            line-height: 24px;

            &-right {
                font-size: 12px;
                line-height: 24px;
                text-align: left;
                background-color: $gray-1;
                border-radius: 3px;
                padding: 10px;
            }
        }

        .title-note {
            border: 1px solid $red-2;
            padding: 10px;
            border-radius: 3px;
        }

        .post-upload-note {
            font-size: 14px;
            line-height: 26px;
            width: 60%;
            margin: auto;
        }

        .text-right {
            text-align: right;
        }

        .title {
            margin-bottom: 10px;
        }

        .uploaded-file {
            border: 1px dashed $light-blue-3;
            background: white;
            width: 750px;
        }

        .remove-uploaded-file-btn {
            border: none;
            background: none;
            color: black;
            position: absolute;
            right: 0;
            top: 0;
            margin: 10px;
        }

        .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
            margin-bottom: 5px;
        }

        .kisoku-upload-container {
            align-items: center;
            display: flex;
        }

        .pdf-upload-icon {
            font-size: 2em;
        }

        .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
            color: $gray-5;
            font-size: 38px;
        }

        .kisoku-uploaded-file-table {
            button.hidden-button{
                visibility: hidden !important;
            }
        }

        .kisoku-action-file-table {

        }

        .kisoku-action-file-table {
            button.hidden-button{
                visibility: hidden !important;
            }
        }

        .action-buttons {
            float: right;

            button.filter-button {
                color: black;
                border: 1px solid $gray-6;
                padding: 3px 10px;
                background: white;
            }

            .ant-input-search-button {
                border: 1px solid $gray-6 !important;
                background-color: white;
            }

            .ant-btn-dangerous {
                background: $red-1;
            }
        }

        .custom-table {
            .action-buttons {
                float: none;

                .ant-btn {
                    min-width: 100px;

                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }

                .edit-button {
                    background: $light-blue-1;
                    color: $button-primary-color;
                    border: 1px solid $button-primary-color;
                }

                .delete-button {
                    background: $red-1;
                    color: $red-3;
                    border: 1px solid $red-3;
                }
            }

            .ant-table {
                background: none;
            }

            .ant-table-thead > tr > th {
                border-bottom: 2px solid $gray-6;
                background: none;
            }

            .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
                display: none;
            }

            .ant-table-tbody > tr > td {
                border-bottom: none;
                padding: 5px 16px;
            }

            .ant-table-tbody > .bordered > td {
                border-bottom: 1px solid $gray-6;
                padding: 5px 16px;
            }

            .ant-table-tbody > .not-bordered > td {
                border-bottom: none;
                padding: 5px 16px;
            }
        }
    }

    .request-meeting-modal {
        width: 700px !important;

        .ant-modal-body {
            padding: 50px 10% !important;
        }

        form {
            width: 100%;
        }

        .red {
            color: $red-3;
        }

        ul {
            padding-left: 20px;
        }

        .daido-joins-card {
            border: 1px solid $gray-3;
            background: $gray-1;

            .ant-card-body {
                padding: 15px;
            }
        }

        .meeting-request-card {
            border: 1px solid $light-blue-2;
            background: $light-blue-1;
        }

        .action-buttons {
            display: block;
        }
    }

    .kisoku-meetings-modal {
        .ant-modal-body {
            padding: 50px 13% !important;
        }
    }

    .kisoku-meetings {
        .title-helper-text {
            color: $gray-5;
            font-size: 11px;
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
        }

        .copy-button {
            background: $light-blue-1;
        }

        .meeting-datetime {
            color: #66B828;
        }

        .status {
            &-scheduled {
                color: #006BB5;
            }

            &-waiting {
                color: #F4A353;
            }

            &-finished {
                color: $green-3;
            }

            &-canceled {
                color: $red-3;
            }
        }
    }

    .card-container {
        display: flex;
        width: 100%;
        padding: 15px;

        &.dark-gray {
            border: 1px solid $gray-4;
            background: rgba(196, 196, 196, 0.4);
            color: $gray-4
        }

        &.gray {
            border: 1px solid $gray-3;
            background: $gray-2;
        }

        &.light-blue {
            border: 1px solid $light-blue-2;
            background: $light-blue-1;
        }

        &.orange {
            border: 1px solid rgba(196, 196, 196, 0.4);
            background: #FFF5EC;
        }

        &.green {
            border: 1px solid $green-2;
            background: $green-1;
        }

        &.red {
            border: 1px solid $red-2;
            background: $red-1;
        }

        &.purple {
            border: 1px solid $purple-2;
            background: $purple-1;
        }

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    .instruction-container {
        &-one {
            width: 670px;
        }
        &-two {
            width: 770px;
        }
    }

    .file-upload-input {
        width: 750px;
    }
}
