footer.landing-footer {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #656565;
    height: 250px;

    .footer-wrapper {
        background-color: #F2F2F2;
        font-size: 14px;
        height: 100%;
        padding: 50px 250px;
    }

    .copyright {
        font-size: 12px;
        flex-direction: row-reverse;
    }

    .logo {
        display: flex;
        flex-direction: column;

        img {
            height: 45px;
            align-self: start;
        }

        span {
            color: inherit;
        }
    }

    hr.line {
        width: 100%;
        background-color: #999999;
        opacity: 1;
    }

    .footer-items {
        margin-right: auto;
        display: grid;
        grid-auto-flow: column;
        grid-gap: 50px;

        li {
            list-style: none;
        }
    }
}
