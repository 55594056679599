footer.landing-footer {
    height: auto;

    .footer-wrapper {
        padding: 50px 10%;
    }

    .copyright {
        flex-direction: column-reverse;

        .footer-items {
            margin-right: auto;
            grid-gap: 20px;
            margin-bottom: 20px;
        }
    }
}
