body#work-regulation {
    section.header {
        img {
            width: 65%;
        }
    }

    section.static-content {
        padding: 20px 10px 100px 10px;
    }

    section.primary-content {
        margin: 0 20px;
    }

    section.sub-header {
        .content {
            align-items: end;
        }
    }

    .ant-card {
        .topic-text-col {
            padding: 0 20px;
        }
    }

    section.secondary-content {
        .illustration-img {
            width: 100%;
        }
    }

    .topic-list .title {
        padding: 20px 0 !important;
    }

    .topics {
        margin: 0 !important;
    }

    .topic-container {
        padding: 20px 10px 30px 10px;
        min-height: calc(
            100vh
            - 225px// navbar
            - 273.69px// footer
            - 42px // breadcrumbs
        );
    }

    section.regulations-content {
        width: 90%;

        .regulations-table {
            display: block;
        }

        .ant-table-wrapper {
            width: 100%;
        }
    }
}
