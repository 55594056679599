body#dashboard {
    .dashboard-container {
        min-height: 100vh;
    }

    .dashboard-sider {
        overflow: unset;
        position: fixed;
        height: calc(100vh - 75px);
        left: unset;
        z-index: 101;
        margin-top: 75px;
    }

    .ant-layout-sider-zero-width-trigger {
        top: 0;
    }

    .header-container {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .page-actions {
        margin-left: 0;
    }

    .dashboard-content {
        padding: calc(10% + 75px) 5% 75px;

        .ant-layout-content {
            margin-left: 0;
            flex: unset;
        }
    }

    .dashboard-management {
        .dashboard-profile {
            padding: 0;

            .ant-col {
                word-break: break-all;
            }
        }

        button.edit-profile {
            margin-left: 0;
            margin-bottom: 25px;
        }

        .title {
            margin-bottom: 10px;
        }
    }

    .dashboard-content > .ant-layout-content {
        width: 100%;
    }

    .specialist-review {
        .feedback-buttons {
            .ant-btn {
                width: 48%;

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
    }

    .working-regulation, .kisoku-meetings {
        .upload-note {
            &-right {
                text-align: left;
            }
        }

        .post-upload-note {
            width: 100%;
        }
    }

    .kisoku-meetings {
        .title-helper-text {
            line-height: 15px;
        }

        .card-container {
            flex-direction: column;
        }
    }
}
