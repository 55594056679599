body#work-regulation {
    section.header {
        position: relative;

        background: url("../../images/main_header.jpg") center no-repeat;
        width: 100%;
        background-size: cover;
        background-position-y: 70%;

        img {
            width: 30%;
        }

        .content {
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(180deg, rgba(1, 160, 221, 0.65) 55.21%, rgba(1, 160, 221, 0) 100%);
        }
    }

    section.sub-header {
        position: relative;
        background: url("../../images/kisoku_sub_header.jpg") center no-repeat;
        width: 100%;
        background-size: cover;
        height: 225px;

        img {
            width: 30%;
        }

        .content {
            background: linear-gradient(180deg, rgba(1, 160, 221, 0.65) 55.21%, rgba(1, 160, 221, 0) 100%);
            background-size: cover;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            height: 225px;

            .title {
                margin-top: 10%;
                font-size: 36px;
                color: #FFFFFF;
            }
        }
    }

    section.static-content {
        padding: 20px 200px 100px 200px;
        min-height: calc(100vh - ($navbar-height + $footer-height));
        .ant-card {
            background: #fff;
        }
    }

    footer.landing-footer {
        background-image: url("../../images/kisoku_footer.jpg");
        color: #ffffff;

        .footer-wrapper {
            background: linear-gradient(0deg, rgba(1, 160, 221, 0.48), rgba(1, 160, 221, 0.48));
        }
    }

    .landing-container {
        min-height: calc(100vh - ($navbar-height + $footer-height));
        margin: 0;
    }

    section.primary-content {
        text-align: center;
        align-items: center;
        padding-top: 53px;
        padding-bottom: 53px;

        .content {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            margin-top: 15px;
            display: block;
        }

        .topics {
            margin: 2vw 12vw;
        }
    }

    section.secondary-content {
        background: #F3FAFF;
        text-align: center;
        align-items: center;
        padding-top: 53px;
        padding-bottom: 53px;

        .content {
            color: #656565;
            font-size: 12px;
            font-weight: 500;
            margin-top: 8px;
            display: block;
        }

        .illustration-img {
            mix-blend-mode: multiply;
            width: 976px;
        }

        .working-regulation-login {
            background-color: white;
            padding: 10px;
            border: 1px $gray-3 solid;
            border-radius: 3px;
            font-size: 14px;
        }
    }

    .text-justify {
        text-align: justify !important;
    }

    .title {
        color: #01A0DD;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        display: block;
    }

    .subtitle {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 15px;
        display: block;
    }

    .ant-card {
        background: #F2F2F2;
        margin-top: 10px;

        .topic-date-col {
            font-size: 12px;
            padding: 20px;

            .topic-date-container {
                background: #999999;
                color: #ffffff;
                padding: 2px;
            }
        }

        .topic-text-col {
            font-size: 14px;
            text-align: justify;
            padding: 20px;
        }

        .topic-button-col {
            padding: 10px;

            .ant-btn {
                width: 88px;
                font-size: 11px;
            }
        }
    }

    .footer-items a {
        color: #FFFFFF !important;;
    }

    .badge {
        background-color: #F6FFED;
        border: 1px solid #B7EB8F;
        border-radius: 2px;
        color: #52C41A;
        font-size: 12px;
        padding: 2px 5px;
        vertical-align: middle;
    }

    .topic-list {
        padding-top: 0 !important;
        min-height: calc(100vh - ($navbar-height + $footer-height));
    }

    .topic-container {
        padding: 0 182px 30px 182px;
        min-height: calc(
            100vh
            - 225px// navbar
            - 250px// footer
            - 112px // breadcrumbs
        );

        .title {
            color: black;
            margin-bottom: 5px;
        }

        p.content {
            white-space: pre-line;
        }
    }

    section.regulations-content {
        width: 75%;
        text-align: left;
        margin: 0 auto 53px;

        .note {
            padding: 10px 10%;
            margin-bottom: 20px;
            background: #F3FAFF;
        }

        .regulations-table {
            display: flex;
            column-gap: 20px;
        }

        .ant-table-wrapper {
            width: 50%;
        }
    }
}

